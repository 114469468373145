.box-out {
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    position: relative;

    @media (max-width: 500px) {
        flex-direction: column;
      }
}

.box-inner {
    /* display: none; */
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

.book {
    width: 140px;
    height: 220px;
    background-color: rgb(62, 71, 152);
    transition: all .3s ease-in-out;
    transform-origin: left center 0px;
    transform-style: preserve-3d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    margin: 1px;
    /* padding-right: 10px; */
}

.box-out .book::after {
    content: " ";
    display: flex;
    opacity: 0;
    width: 140px;
    height: 220px;
    position: relative;
    /* left: 8px; */
    transition: all .3s ease;
}

.box-out .book::before {
    content: " ";
    z-index: 999;
    display: flex;
    width: 20px;
    height: 100px;
    opacity: 0;
    position: relative;
    top: -12px;
    right: 8px;
    transition: all .25s;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAABhCAYAAABh23lYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHCSURBVHgB7dxPTsJAHMXxN7Xu3UhigobxJHACvQFwAr2BcgO8AZxE7uGiDZiQyEIW7oSOUyKbKgLS8u+9b9JNF818fum0XdWUhy9VEDZBGIdBGD6DsFM3bQYgTnjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNZ2hR/7o+UcmnCIsaNCbLcU/RR8oB1bO/4+17l8ixoGeIBBBVvMXI0ih+L7Df2j8iiqnvgh+AVVUXDOTZvF4tNb2qC7DJ1tNgSDO78tblFQxeE92l+0NSjZDjaoMowqyYl7hDF15JzL+x+YHtxLgFq/ZO2m8LT4wsb90nUjmMD61XaRc7ng5+jBua29ntsecq6oIWyELxqdLe8h/GvPp+h0T28D/FezZ8Ip7pHgZt3X5NoPvH1BZ5sNIUTDL7C+6hBWxxt0gk+00tsOe96qH0zL8GO4pBtMg/YhoLMtG8Ii/EpfY4fSoiFk8UeFzpYdwhz/jiNGZ0uHEBjUEzftohJFZyAsdX8BM0/amLC3spYAAAAASUVORK5CYII=');
    background-size: 16px;
    background-repeat: no-repeat;
}

/*------ background-pic ------*/

.books-1 {
    background: url('https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1412064931i/23168817.jpg');
    background-size: 140px 220px;
}

.books-2 {
    background: url('https://m.media-amazon.com/images/I/71wQNKj+jeL._SL1500_.jpg');
    background-size: 140px 220px;
}

.books-3 {
    background: url('https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1615552073i/12067.jpg');
    background-size: 140px 220px;
}

/* ----- hover ----- */

.book:hover {
    cursor: pointer;
    transform: rotateY(-28deg) rotateZ(-2deg) scale(1.02);
    -webkit-backface-visibility: hidden;
    box-shadow: 1px 3px 2px #353d85, 20px 8px 0 #525dc4;
    /* transform: scale(1.02); */
}

.book:hover::after {
    content: " ";
    display: flex;
    opacity: 1;
    width: 140px;
    height: 220px;
    position: relative;
    left: 8px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, 0) 60%);
}

.book:hover::before {
    transform: translateY(9px);
    opacity: 1;
}

